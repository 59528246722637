import { render, staticRenderFns } from "./ranking-organic-sponsored-report.vue?vue&type=template&id=612e5823&scoped=true&"
import script from "./ranking-organic-sponsored-report.ts?vue&type=script&lang=ts&"
export * from "./ranking-organic-sponsored-report.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "612e5823",
  null
  
)

export default component.exports